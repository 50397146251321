import Axios from "../ServiceCaller";

let CreateOrder = async (customerDetails) => {
    const {data} = await Axios.post('/orders', customerDetails);
    return data;
};

let getOrderDataByToken = async (token) => {
    const {data} = await Axios.get(`/orders/order/${token}`);
    return data.Order;
};

let confirmOrder = async (orderData) => {
    const { data } = await Axios.put("/orders/confirm", orderData);
    return data;
};

export const orderService = {
    CreateOrder,
    getOrderDataByToken,
    confirmOrder,
};