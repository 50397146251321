import "./auth.css"
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {accountService} from "../../_services";
import {useState} from "react";
import {Link} from "react-router-dom";

import iconLogo from "../../assets/images/global/icon_lcmodemaroc.png"
import {BiLock, BiMailSend} from "react-icons/bi";

const Login = () => {

    useEffect(() => {
        document.title = "lcmodemaroc - Login";
    }, []);

    let navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/admin/orders', {replace: true})
            );
        }
    }, [navigate]);

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        accountService.login(credentials)
            .then(res => {
                if (res.data.status === 200) {
                    accountService.saveToken(res.data.jwt_lcm);
                    navigate('/admin/orders', {replace: true});
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="authentication-page h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-8 col-xl-6">
                        <div className="authentication-content bg-white px-1 px-md-2 py-3 py-md-4 rounded-3 shadow-lg">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form p-3 p-lg-4 p-xxl-5">
                                        <div
                                            className="text-center mb-1 d-flex justify-content-center align-items-center">
                                            <Link to="/home"><img width="75" src={iconLogo} className="img-fluid" alt="lcmodemaroc"/></Link>
                                        </div>
                                        <div className="text-center black-text-color fw-bold fs-3">مرحبًا بعودتك</div>
                                        <div className="text-center mb-4 gray-text-color">
                                            الرجاء إدخال بيانات الاعتماد الخاصة بك
                                        </div>
                                        <form onSubmit={onSubmit} dir="rtl">
                                            <div className="form-group mb-4 position-relative">
                                                <div
                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                    <BiMailSend size="25" color="#b7b7b7"/>
                                                </div>
                                                <input type="text" className="form-control"
                                                       placeholder="عنوان بريدك الإلكتروني" name="email" id="email"
                                                       value={credentials.email} onChange={onChange}/>
                                            </div>
                                            <div className="form-group mb-4 position-relative">
                                                <div
                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                    <BiLock size="25" color="#b7b7b7"/>
                                                </div>
                                                <input type="password" className="form-control"
                                                       placeholder="كلمة السر خاصتك"
                                                       name="password" id="pass" value={credentials.password}
                                                       onChange={onChange}/>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit"
                                                        className="btn btn-block submit-button w-100 fw-bold">تسجيل الدخول
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;