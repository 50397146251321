import {Footer, Header, OrderRow} from "./Private";
import {adminOrderService} from "../../_services";
import React, {useEffect, useState} from "react";

const PendingOrders = () => {
    const [ordersData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [refresh, setRefresh] = useState(false);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminOrderService.getPendingOrders();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching orders data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(ordersData); // If search query is empty, show all orders
            return;
        }

        const filtered = ordersData.filter((order) =>
            Object.values(order).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, ordersData]);

    return (
        <>
            <Header title="الطلبيات"/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="order-all-search-holder">
                            <label htmlFor="search" className="black-text-color mb-1 pe-2">البحث</label>
                            <input
                                type="text"
                                className="form-control py-2"
                                value={searchQuery}
                                id="search"
                                onChange={handleSearchInputChange}
                                placeholder="البحث"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-white p-3 rounded-3">
                    <div className="main-orders-page table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50"></td>
                                <td className="text-black-50">الاسم</td>
                                <td className="text-black-50">المدينة</td>
                                <td className="text-black-50">رقم الهاتف</td>
                                <td className="text-black-50">المجموع</td>
                                <td className="text-black-50">حالة الطلبية</td>
                                <td className="text-black-50">تاريخ الطلبية</td>
                                <td className="text-black-50"></td>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((order) => (
                                    <OrderRow
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        key={order.id}
                                        order_id={order.id}
                                        customer_name={order.customer_name}
                                        city={order.city}
                                        phone_number={order.phone_number}
                                        total="*****"
                                        status={order.status}
                                        created_date={order.created_date}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">No matching orders found</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default PendingOrders;