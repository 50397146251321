import {useEffect} from "react";
import mainLogo from "../../../assets/images/global/lcmodemaroc_logo.png";
import {Link} from "react-router-dom";

const Header = (props) => {
    useEffect(() => {
        document.title = `lcmodemaroc - ${props.title}`;
    }, [props.title]);

    return (
        <div className="the-main-header px-3 bg-white sticky-top d-flex align-items-center justify-content-between">
            <div className="d-none d-lg-block page-title fw-bold main-dark-color">{props.title}</div>
            <div className="d-lg-none header-logo-holder">
                <Link to="/admin/orders"
                      className="logo-holder d-flex align-items-center justify-content-center h-100">
                    <img src={mainLogo} alt="lcmodemaroc" className="logo-img"/>
                </Link>
            </div>
        </div>
    );
}
export default Header;