import React, {useEffect, useState} from "react";
import {adminOrderService} from "../../../_services";
import {OrderRow} from "../../../pages/private/Private";

const OrdersFilteredData = (props) => {
    const {status, searchQuery} = props;
    const [ordersData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminOrderService.getOrders(status);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching orders data:", error);
            }
        };

        fetchData();
    }, [status, refresh]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(ordersData); // If search query is empty, show all orders
            return;
        }

        const filtered = ordersData.filter((order) =>
            Object.values(order).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, ordersData]);

    return (
        <div className="main-orders-page table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <td className="text-black-50"></td>
                    <td className="text-black-50">الاسم</td>
                    <td className="text-black-50">المدينة</td>
                    <td className="text-black-50">رقم الهاتف</td>
                    <td className="text-black-50">المجموع</td>
                    <td className="text-black-50">حالة الطلبية</td>
                    <td className="text-black-50">تاريخ الطلبية</td>
                    <td className="text-black-50"></td>
                </tr>
                </thead>
                <tbody>
                {filteredData.length > 0 ? (
                    filteredData.map((order) => (
                        <OrderRow
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            key={order.id}
                            order_id={order.id}
                            customer_name={order.customer_name}
                            city={order.city}
                            phone_number={order.phone_number}
                            status={order.status}
                            total={order.status === 'في انتظار الثمن' ? "*****" : order.total}
                            created_date={order.created_date}
                        />
                    ))
                ) : (
                    <tr>
                        <td colSpan="8" className="text-center">No matching orders found</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default OrdersFilteredData;