import Axios from "../ServiceCaller";

// Authentication
let signup = (userDetails) => {
    return Axios.post('/auth/signup', userDetails);
}
let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
};
let saveToken = (lcm_token) => {
    localStorage.setItem('lcm_token', lcm_token);
};
let getToken = () => {
    return localStorage.getItem('lcm_token');
};
let logout = () => {
    localStorage.removeItem('lcm_token');
};

// Access
let isLogged = () => {
    let lcm_token = localStorage.getItem('lcm_token');
    return !!lcm_token;
};
let isAdmin = async () => {
    const {data} = await Axios.get('/account/is-admin');
    return data.User;
};

// User Data
let getUserData = async () => {
    const {data} = await Axios.get('/account/user-data');
    return data.User;
};
let getUserSocialAccounts = async () => {
    const {data} = await Axios.get('/account/social-accounts');
    return data;
};
let getStepsStatus = async () => {
    const {data} = await Axios.get('/account/is-steps-completed');
    return data.Steps;
};
let updateProfilePicture = async (profilePic) => {
    const { data } = await Axios.put("/account/update-profile-pic", profilePic);
    return data;
};
let updateProfileSocials = async (socialAccounts) => {
    const { data } = await Axios.put("/account/update-social-accounts", socialAccounts);
    return data;
};

export const accountService = {
    // Authentication
    signup,
    login,
    saveToken,
    getToken,
    logout,
    // Access
    isLogged,
    isAdmin,
    // User Data
    getUserData,
    getUserSocialAccounts,
    getStepsStatus,
    updateProfilePicture,
    updateProfileSocials,
};