import {useEffect, useState} from "react";
import Select from 'react-select'
import {orderService} from "../../_services";
import {BiFullscreen, BiPlus, BiTrash, BiUser, BiXCircle} from "react-icons/bi";
import {LiaCitySolid} from "react-icons/lia";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {SlWallet} from "react-icons/sl";
import {AiOutlinePhone} from "react-icons/ai";
import DeliveryCities from "../../_services/data/livraison_cities_last.json"
import holderImage from "../../assets/images/global/holder_image.jpg"
import {FcPaid} from "react-icons/fc";
import {FallingLines, ThreeDots} from "react-loader-spinner";

const CustomOrder = () => {
    const [customerDetails, setCustomerDetails] = useState({
        order_name: '',
        order_address: '',
        order_phone_number: '',
        order_notes: '',
    });

    const [orderCity, setOrderCity] = useState(0);
    const [orderDeliveryFee, setOrderDeliveryFee] = useState(0);
    const [cityData, setCityData] = useState([]);
    const [images, setImages] = useState([]);
    const [counter, setCounter] = useState(0);
    const [sizeInputs, setSizeInputs] = useState([]);
    const [colorInputs, setColorInputs] = useState([]);
    const [priceInputs, setPriceInputs] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [showTotal, setShowTotal] = useState(false);
    const [orderFinished, setOrderFinished] = useState(false);
    const [creatingLoading, setCreatingLoading] = useState(false);
    const [previewImageIndex, setPreviewImageIndex] = useState(null);

    const onImageUpload = (event) => {
        const files = event.target.files;
        onCreateImage(files);
    };

    const onCreateImage = (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            setImages(prevImages => [...prevImages, file]);
            setCounter(prevCounter => prevCounter + 1);
        }
    };

    const onDeleteImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
        setSizeInputs(prevInputs => prevInputs.filter((_, i) => i !== index));
        setColorInputs(prevInputs => prevInputs.filter((_, i) => i !== index));
        setPriceInputs(prevInputs => prevInputs.filter((_, i) => i !== index));
        setCounter(prevCounter => prevCounter - 1);
    };

    useEffect(() => {
        calculPriceTotal();
    }, [priceInputs, orderDeliveryFee]);

    const calculPriceTotal = () => {

        let total = 0;
        for (let i = 0; i < priceInputs.length; i++) {
            const price = parseFloat(priceInputs[i]);
            if (!isNaN(price)) {
                total += price;
            }
        }

        const deliveryFee = parseFloat(orderDeliveryFee);
        if (!isNaN(deliveryFee)) {
            total += deliveryFee;
        }

        if (deliveryFee === 0 || (deliveryFee === total)) {
            setShowTotal(false);
        } else {
            setShowTotal(true);
        }

        // Update the subTotal state
        setSubTotal(total);
    };

    const onCityChange = (event) => {
        if (event) {
            const delivery_fee = parseFloat(event.livraison_fees.replace(/\D/g, ''));
            const order_city_value = event.value || '';
            setOrderCity(order_city_value);
            if (event.ref === "TGR") {
                setOrderDeliveryFee((delivery_fee) + " درهم");
            } else {
                setOrderDeliveryFee((delivery_fee + 5) + " درهم");
            }
        } else {
            const delivery_fee = 0;
            setOrderDeliveryFee(delivery_fee);
        }
        calculPriceTotal();
    };


    useEffect(() => {
        images.forEach((image, index) => {
            if (priceInputs[index] && priceInputs[index] > 0) {
                setConfirmedDisabled(false)
                setPriceAskedDisabled(true)
            } else {
                setConfirmedDisabled(true)
                setPriceAskedDisabled(false)
            }
        })
    }, [priceInputs]);

    useEffect(() => {
        setCityData(DeliveryCities)
    }, []);

    const onChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value,
        });
    }

    const [confirmedDisabled, setConfirmedDisabled] = useState(true);
    const [priceAskedDisabled, setPriceAskedDisabled] = useState(false);

    const formData = new FormData();

    const onSubmit = async (e) => {
        let submitted = true;
        images.forEach((image, index) => {
            if (!sizeInputs[index] || !colorInputs[index]) {
                submitted = false
            }
        })
        if (customerDetails.order_phone_number.length < 10 || !customerDetails.order_phone_number.startsWith('0') || isNaN(customerDetails.order_phone_number)) {
            submitted = false
        }
        if (submitted) {
            setCreatingLoading(true);
            e.preventDefault();
            setSubmitClicked(true);
            if (!customerDetails.order_name ||
                !orderCity ||
                !customerDetails.order_phone_number
            ) {
                return {}
            }
            formData.append("order_name", customerDetails.order_name);
            formData.append("order_city", orderCity);
            formData.append("order_address", customerDetails.order_address);
            const match = orderDeliveryFee.match(/\d+/);
            const deliveryFee = match ? match[0] : null;
            formData.append("order_delivery_fee", deliveryFee);
            formData.append("order_phone_number", customerDetails.order_phone_number);
            formData.append("order_notes", customerDetails.order_notes);
            formData.append("order_items_counter", counter);
            formData.append("order_sub_total", subTotal);
            images.forEach((image, index) => {
                if (!sizeInputs[index] ||
                    !colorInputs[index]
                ) {
                    return {}
                }
                formData.append(`order_item_image_${index}`, image);
                formData.append(`order_item_size_${index}`, sizeInputs[index]);
                formData.append(`order_item_color_${index}`, colorInputs[index]);
                formData.append(`order_item_price_${index}`, priceInputs[index]);
            });
            setOrderFinished(true);
            try {
                await orderService.CreateOrder(formData);
            } catch (error) {
                console.error("Error creating order:", error);
            } finally {
                setCreatingLoading(false);
            }
        }
    }


    const onSubmitOrderConfirmation = (e) => {
        formData.append("submit_type", 'order_confirmation');
        onSubmit(e);
    }

    const onSubmitPriceRequest = (e) => {
        formData.append("submit_type", 'price_request');
        onSubmit(e);
    }

    const onItemSizeChange = (index, value) => {
        setSizeInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = value;
            return newInputs;
        });
    };

    const onItemColorChange = (index, value) => {
        setColorInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = value;
            return newInputs;
        });
    };

    const onItemPriceChange = (index, value) => {
        setPriceInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = value;
            return newInputs;
        });
        calculPriceTotal();
    };

    const previewImage = (index) => {
        setPreviewImageIndex(index);
    };

    const closePreview = () => {
        setPreviewImageIndex(null);
    };

    return (
        <div className="custom-order-page h-100 my-auto">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-10 col-xl-8">
                        <div className="authentication-content bg-white px-1 px-lg-2 py-3 rounded-3 shadow-lg">
                            <div className="row no-gutters">
                                <div>
                                    {orderFinished ? (
                                        <>
                                            {creatingLoading ? (
                                                <div className="auth-form p-3 text-center">
                                                    <div className="text-center black-text-color fw-bold fs-5">
                                                        جاري الطلب...
                                                    </div>
                                                    <div className="text-black-50 small mb-2">المرجو انتظار بضع لحظات
                                                    </div>
                                                    <div
                                                        className="text-center d-flex align-items-center justify-content-center">
                                                        <FallingLines
                                                            className="mx-auto"
                                                            visible={true}
                                                            height="80"
                                                            width="80"
                                                            color="#4fa94d"
                                                            radius="9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="auth-form p-3">
                                                    <div className="text-center mb-3"><FcPaid size="100"/></div>
                                                    <div className="text-center black-text-color fw-bold fs-5 mb-3">
                                                        شكرا لطلبكم!
                                                    </div>
                                                    <div className="text-center gray-text-color fw-bold fs-5 mb-3">
                                                        سيتصل بك فريقنا لتأكيد الطلب
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="auth-form p-3">
                                            <div className="text-center black-text-color fw-bold fs-5 mb-3">
                                                إضافة معلومات الطلبية
                                            </div>
                                            <form encType="multipart/form-data">
                                                <div className="form-group imgs-hoder mb-3">
                                                    <div className="gray-text-color small">
                                                        1- اخد لقطة الشاشة "capture d'écran" للمنتوج الذي تود شرائه
                                                    </div>
                                                    <div className="gray-text-color mb-2 small">
                                                        2- اضافة الصورة من معرض الصور
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 mb-3">
                                                            <div className="upload-box-holder w-100 h-100">
                                                                <label htmlFor="order_images_input"
                                                                       className="w-100 h-100">
                                                                    <div
                                                                        className="shadow h-100 c-pointer image-upload-btn py-3 rounded-3 bg-white w-100 d-flex align-items-center justify-content-center">
                                                                        <div>
                                                                            <div
                                                                                className="icon-holder rounded-circle mx-auto mb-1"
                                                                                style={{width: "fit-content"}}>
                                                                                <BiPlus size="40"/>
                                                                            </div>
                                                                            <div className="small upload-text">إضافة
                                                                                صورة
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                                <input
                                                                    className="d-none"
                                                                    type="file"
                                                                    id="order_images_input"
                                                                    name="order_items_images[]"
                                                                    multiple
                                                                    accept="image/*"
                                                                    onChange={onImageUpload}
                                                                />
                                                            </div>
                                                        </div>
                                                        {images.length < 1 && (
                                                            <div className="col-6 mb-3">
                                                                <div
                                                                    className="uploaded-img-box shadow rounded-3 bg-white">
                                                                    <div className="img-holder">
                                                                        <img src={holderImage} alt="Image Holder"
                                                                             className="rounded-top-3"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {images.map((imgSrc, index) => (
                                                            <div className="col-6 mb-3" key={index}>
                                                                <div
                                                                    className="uploaded-img-box shadow rounded-3 bg-white">
                                                                    <div className="img-holder position-relative">
                                                                        <img src={URL.createObjectURL(images[index])}
                                                                             alt="add_image"
                                                                             className="rounded-top-3 img_style"/>
                                                                        <div
                                                                            className="position-absolute top-0 end-0 pe-2 pt-1 text-white c-pointer">
                                                                            <BiTrash size="20" className="text-danger"
                                                                                     onClick={() => onDeleteImage(index)}/>
                                                                        </div>
                                                                        <div
                                                                            className="position-absolute top-0 start-0 ps-2 pt-1 text-white c-pointer">
                                                                            <BiFullscreen size="20"
                                                                                          className="text-white"
                                                                                          onClick={() => previewImage(index)}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="img-inputs-holder mt-1 pb-1">
                                                                        <div className="d-flex px-1 mb-1">
                                                                            <div className="px-1">
                                                                                <label
                                                                                    className="gray-text-color text-real-small label-text"
                                                                                    htmlFor={`order_item_size_${index}`}>
                                                                                    المقاس :
                                                                                </label>
                                                                            </div>
                                                                            <div className="px-1 w-100">
                                                                                <input type="text"
                                                                                       onChange={(e) => onItemSizeChange(index, e.target.value)}
                                                                                       id={`order_item_size_${index}`}
                                                                                       name={`order_item_size_${index}`}
                                                                                       required
                                                                                       className={`form-control form-control-sm ${submitClicked && !sizeInputs[index] && 'border-danger'}`}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex px-1 mb-1">
                                                                            <div className="px-1">
                                                                                <label
                                                                                    className="gray-text-color text-real-small label-text"
                                                                                    htmlFor={`order_item_color_${index}`}>
                                                                                    اللون :
                                                                                </label>
                                                                            </div>
                                                                            <div className="px-1 w-100">
                                                                                <input type="text"
                                                                                       onChange={(e) => onItemColorChange(index, e.target.value)}
                                                                                       id={`order_item_color_${index}`}
                                                                                       name={`order_item_color_${index}`}
                                                                                       required
                                                                                       className={`form-control form-control-sm ${submitClicked && !colorInputs[index] && 'border-danger'}`}/>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="small-input-price gray-text-color text-real-small text-center mb-1">الثمن
                                                                            المقترح من طرف البائع:
                                                                        </div>
                                                                        <div className="d-flex px-1 mb-1">
                                                                            <div className="px-1 w-100">
                                                                                <input type="text"
                                                                                       onChange={(e) => onItemPriceChange(index, e.target.value)}
                                                                                       id={`order_item_price_${index}`}
                                                                                       name={`order_item_price_${index}`}
                                                                                       className="form-control form-control-sm"/>
                                                                            </div>
                                                                            <div className="px-1">
                                                                                <label
                                                                                    className="gray-text-color text-real-small label-text"
                                                                                    htmlFor={`order_item_price_${index}`}>
                                                                                    درهم
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {previewImageIndex !== null && (
                                                        <div className="previw-image bg-black">
                                                            <div
                                                                className="previw-image-content position-relative d-flex align-items-center justify-content-center">
                                                                <div className="position-absolute top-0 end-0 p-4">
                                                                    <BiXCircle size="30" color="#fff"
                                                                               onClick={closePreview}
                                                                               className="c-pointer"
                                                                    />
                                                                </div>
                                                                <img className=""
                                                                     src={URL.createObjectURL(images[previewImageIndex])}
                                                                     alt="Preview"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_name">الاسم :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <BiUser size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               className={`form-control ${submitClicked && !customerDetails.order_name && 'border-danger'}`}
                                                               placeholder="الاسم الكامل" name="order_name"
                                                               id="order_name"
                                                               value={customerDetails.order_name} onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="row m-0">
                                                    <div className="col-lg-6 p-0 ps-lg-2">
                                                        <div className="mb-3">
                                                            <label className="mb-2 ps-3 gray-text-color small"
                                                                   htmlFor="order_city">المدينة :</label>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                    <LiaCitySolid size="20" color="#b7b7b7"/>
                                                                </div>
                                                                <Select
                                                                    className={`custom-searchable-select ${submitClicked && !orderCity && ' is-empty-input'}`}
                                                                    defaultValue={orderCity}
                                                                    isClearable={true}
                                                                    isRtl={true}
                                                                    isSearchable={true}
                                                                    name="order_city"
                                                                    id="order_city"
                                                                    options={cityData}
                                                                    onChange={(e) => onCityChange(e)}
                                                                    placeholder="اختر المدينة..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 p-0 pe-lg-2">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2 ps-3 gray-text-color small"
                                                                   htmlFor="orderDeliveryFee">مبلغ التوصيل :</label>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                    <SlWallet size="20" color="#b7b7b7"/>
                                                                </div>
                                                                <input type="text" className="form-control" disabled
                                                                       placeholder="مبلغ التوصيل"
                                                                       name="orderDeliveryFee" id="orderDeliveryFee"
                                                                       value={orderDeliveryFee}
                                                                       onChange={onChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_address">العنوان :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <HiOutlineLocationMarker size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="عنوان التوصيل" name="order_address"
                                                               id="order_address"
                                                               value={customerDetails.order_address}
                                                               onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_phone_number">رقم الهاتف :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <AiOutlinePhone size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               maxLength="10"
                                                               className={`form-control ${submitClicked && !customerDetails.order_phone_number && 'border-danger'}`}
                                                               placeholder="رقم الهاتف" name="order_phone_number"
                                                               id="order_phone_number"
                                                               value={customerDetails.order_phone_number}
                                                               onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 position-relative">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_notes">معلومات اضافية :</label>
                                                    <div className="position-relative">
                                                    <textarea name="order_notes"
                                                              id="order_notes" rows="3"
                                                              className="form-control order-notes-textarea"
                                                              placeholder="يمكن اضافة أي معلومة او استفسار كتاريخ التوصيل او واستاب..."
                                                              value={customerDetails.order_notes}
                                                              onChange={onChange}>
                                                    </textarea>
                                                    </div>
                                                </div>
                                                {showTotal && (
                                                    <div className="mb-4 d-flex align-items-center">
                                                        <div className="text-black-50 ps-2">
                                                            مجموع الطلبية:
                                                        </div>
                                                        <div className="fw-bold-500">
                                                            {subTotal} درهم
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3">
                                                    <div>
                                                        <button type="button" className="btn submit-button px-5"
                                                                disabled={confirmedDisabled}
                                                                onClick={onSubmitOrderConfirmation}>
                                                            تأكيد الطلب
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="btn submit-button px-5"
                                                                disabled={priceAskedDisabled}
                                                                onClick={onSubmitPriceRequest}>
                                                            طلب الثمن
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomOrder;