import {Link, NavLink} from "react-router-dom";
import mainLogo from "../../../assets/images/global/lcmodemaroc_logo.png"
import {BiCartAlt, BiGroup, BiSolidDashboard} from "react-icons/bi";

const Sidebar = () => {
    const menuItem = [
        // {
        //     path: "/admin/dashboard",
        //     name: "Dashboard",
        //     icon: <BiSolidDashboard size="25"/>
        // },
        {
            path: "/admin/pending-orders",
            name: "طلبيات لإدخال الثمن",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/unconfirmed-orders",
            name: "طلبيات في انتظار التأكيد",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/orders-to-confirm",
            name: "طلبيات مؤكدة",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/orders",
            name: "توزيع الطلبيات المؤكدة",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/customers",
            name: "الزبائن",
            icon: <BiGroup size="30"/>
        },
    ]

    return (
        <div className="the-main-sidebar position-fixed top-0 end-0 d-none d-lg-block">
            <div className="h-100 d-flex flex-column px-3 pb-3">
                <div className="sidebar-logo-holder mb-2">
                    <Link to="/admin/orders"
                          className="logo-holder d-flex align-items-center justify-content-center h-100">
                        <img src={mainLogo} alt="lcmodemaroc" className="logo-img"/>
                    </Link>
                </div>
                <div className="sidebar-links">
                    {
                        menuItem.map((item, index) => (
                            <div className="nav-link mt-2 list-unstyled align-items-center" key={index}>
                                <div className="menu-item">
                                    <NavLink to={item.path} key={index}
                                             className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            {item.icon}
                                        </div>
                                        <div className="nav-text">{item.name}</div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
export default Sidebar;