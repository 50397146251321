import {BiLogoWhatsapp, BiTrash} from "react-icons/bi";
import {adminCustomerService, customerService} from "../../../_services";
import {useState} from "react";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const CustomerRow = (props) => {
    const name = props.full_name || (props.email.match(/^(.+)@/) || [])[1];
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [customerToDelete, setCustomerToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCustomer = async (customerId) => {
        setIsDeleting(true);

        try {
            await customerService.deleteCustomer(props.customer_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting customer video:", error);
        } finally {
            setIsDeleting(false);
            // Close the confirmation modal after deletion
            closeDeleteConfirmationModal();
        }
    };

    const openDeleteConfirmationModal = (customerId) => {
        setCustomerToDelete(customerId);
        setShowConfirmationModal(true);
    };

    const closeDeleteConfirmationModal = () => {
        setCustomerToDelete(null);
        setShowConfirmationModal(false);
    };

    return (
        <tr className="text-nowrap">
            <td>
                {name}
            </td>
            <td>
                {props.city}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    {props.phone_number && (
                        <div className="pb-1">
                            <Link className="social-icon px-1"
                                  to={`https://api.whatsapp.com/send/?phone=${props.phone_number.startsWith('0') ? '212'+props.phone_number.slice(1) : '212'+props.phone_number}`}
                                  target="_blank">
                                <BiLogoWhatsapp size="22" color="#000"/>
                            </Link>
                        </div>
                    )}
                    <div>
                        {props.phone_number}
                    </div>
                </div>
            </td>
            <td>
                <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
            </td>
            <td>
                <span className="text-center">
                      {isDeleting ? (
                          <span className="text-danger">حذف...</span>
                      ) : (
                          <BiTrash
                              onClick={() => openDeleteConfirmationModal(props.customer_id === 1 ? null : props.customer_id)}
                              size="22"
                              className={`remove-icon mx-auto c-pointer ${props.customer_id === 1 ? 'disabled' : ''}`}
                          />
                      )}
                </span>
            </td>
            {/* Confirmation Modal */}
            <Modal show={showConfirmationModal} onHide={closeDeleteConfirmationModal} centered dir="rtl">
                <Modal.Header>
                    <Modal.Title>تحذير</Modal.Title>
                </Modal.Header>
                <Modal.Body>هل أنت متأكد أنك تريد حذف هذا الزبون؟</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closeDeleteConfirmationModal}>إلغاء</button>
                    <button className="btn btn-danger" onClick={() => handleDeleteCustomer(customerToDelete)}>حذف
                    </button>
                </Modal.Footer>
            </Modal>
        </tr>
    );
}

export default CustomerRow;