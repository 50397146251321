import "./public.css";
import {useEffect} from "react";
import {CustomOrder} from "./Public";
import mainLogo from "../../assets/images/global/lcmodemaroc_logo.png"
import mainLogoIcon from "../../assets/images/global/icon_lcmodemaroc.png"
import {Link} from "react-router-dom";
import {BiLogoFacebook, BiLogoInstagram, BiLogoWhatsapp} from "react-icons/bi";

const Home = () => {
    useEffect(() => {
        document.title = "Lc Mode Maroc - Order";
    }, []);

    return (
        <div className="home-page bg-white" dir="rtl">
            <div className="icon-whatsapp-holder position-fixed end-0 bottom-0 mb-3 mb-xl-5 me-3 me-xl-5"
                 style={{zIndex: 9}}>
                <div className="whtsapp-holder">
                    <Link className="social-icon px-1" to="https://api.whatsapp.com/send/?phone=212670998045"
                          target="_blank">
                        <div style={{backgroundColor: "#24d366"}} className="rounded-circle p-1 shadow">
                            <BiLogoWhatsapp size="60" color="#fff"/>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="home-page-content px-0 pt-3 d-flex flex-column h-100" style={{minHeight: "100vh"}}>
                <div className="home-page-header bg-white mb-3">
                    <div className="logo-holder mx-auto">
                        <img className="logo img-fluid" src={mainLogo} alt="lcmodemaroc Logo"/>
                    </div>
                </div>
                <CustomOrder/>
                <div className="footer-holder bg-black text-center text-white-50 pt-4 pb-2 mt-auto">
                    <div className="logo-holder mb-3">
                        <img src={mainLogoIcon} alt="Lc Mode Maroc Logo" width="60"/>
                    </div>
                    <div className="mb-3 px-5 col-sm-11 col-md-10 col-lg-8 col-xl-6 mx-auto text-light">
                        متجرنا هو وجهتك المثالية لتلبية احتياجاتك التسوقية..نهتم بتقديم ملابس عالية الجودة وبأسعار
                        تنافسية..
                        إن كنت من هواة الجديد في عالم الموضة ،فإننا نقدم لك تشكيلة متنوعة من الأزياء لتلبية مختلف
                        الأذواق..
                    </div>
                    <div className="social-icons mb-3">
                        <Link className="social-icon px-1" to="https://www.instagram.com/lc.mode.maroc"
                              target="_blank">
                            <BiLogoInstagram size="25"/>
                        </Link>
                        <Link className="social-icon px-1"
                              to="https://www.facebook.com/people/LC-Mode-Maroc/100092386430015"
                              target="_blank">
                            <BiLogoFacebook size="25"/>
                        </Link>
                    </div>
                    <Link className="social-icon px-1"
                          to="/admin/orders">
                        دخول خاص بالإدارة
                    </Link>
                    <div className="small mt-2">
                        جميع الحقوق محفوظة &copy; 2024 <span className="fw-bold">LC Mode Maroc</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;