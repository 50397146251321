import "./public.css";
import {useEffect, useId, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {adminOrderService, orderService} from "../../_services";
import {BallTriangle} from "react-loader-spinner";
import {BiLogoFacebook, BiLogoInstagram, BiLogoWhatsapp, BiUser} from "react-icons/bi";
import mainLogo from "../../assets/images/global/lcmodemaroc_logo.png";
import mainLogoIcon from "../../assets/images/global/icon_lcmodemaroc.png";
import {LiaCitySolid} from "react-icons/lia";
import Select from "react-select";
import {SlWallet} from "react-icons/sl";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {AiOutlinePhone} from "react-icons/ai";
import OrderItems from "../../components/private/order/OrderItems";
import DeliveryCities from "../../_services/data/livraison_cities_last.json";
import {FcPaid} from "react-icons/fc";

const CustomerOrder = () => {
    useEffect(() => {
        document.title = "Lc Mode Maroc - تأكيد الطلب";
    }, []);

    let {token} = useParams();
    let navigate = useNavigate();
    const [orderData, setData] = useState([]);
    const [orderCity, setOrderCity] = useState(0);
    const [orderDeliveryFee, setOrderDeliveryFee] = useState(0);
    const [cityData, setCityData] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [showTotal, setShowTotal] = useState(false);
    const [formData, setFormData] = useState(new FormData());
    const [submitType, setSubmitType] = useState("");
    const [orderDone, setOrderDone] = useState(false);

    const [customerDetails, setCustomerDetails] = useState({
        id: '',
        order_name: '',
        order_address: '',
        order_phone_number: '',
        order_notes: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await orderService.getOrderDataByToken(token);
                setData(data);
                if (data) {
                    const {
                        id,
                        customer_name,
                        city,
                        order_address,
                        phone_number,
                        order_notes,
                        delivery_fee,
                        sub_total,
                        total
                    } = data;
                    setCustomerDetails({
                        id: id,
                        order_name: customer_name,
                        order_address: order_address,
                        order_phone_number: phone_number,
                        order_notes: order_notes,
                    });
                    const order_delivery_fee = `${delivery_fee} درهم`
                    setOrderCity(city);
                    setOrderDeliveryFee(order_delivery_fee);
                    setSubTotal(sub_total)
                    setShowTotal(total > 0)
                }
            } catch (error) {
                console.error("Error fetching order data:", error);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        calculPriceTotal();
    }, [orderDeliveryFee]);

    const calculPriceTotal = () => {

        let total = 0;
        // for (let i = 0; i < priceInputs.length; i++) {
        //     const price = parseFloat(priceInputs[i]);
        //     if (!isNaN(price)) {
        //         total += price;
        //     }
        // }

        const deliveryFee = parseFloat(orderDeliveryFee);
        if (!isNaN(deliveryFee)) {
            total += deliveryFee;
        }

        if (deliveryFee === 0 || (deliveryFee === total)) {
            setShowTotal(false);
        } else {
            setShowTotal(true);
        }

        // Update the subTotal state
        setSubTotal(total);
    };

    const onCityChange = (event) => {
        if (event) {
            const delivery_fee = parseFloat(event.livraison_fees.replace(/\D/g, ''));
            const order_city_value = event.value || '';
            setOrderCity(order_city_value);
            setOrderDeliveryFee((delivery_fee + 5) + " درهم");
        } else {
            const delivery_fee = 0;
            setOrderDeliveryFee(delivery_fee);
        }
        calculPriceTotal();
    };

    useEffect(() => {
        setCityData(DeliveryCities)
    }, []);

    useEffect(() => {
    }, [submitType]);

    const onChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value,
        });
    }

    const onSubmit = async (e, submit_type) => {
        e.preventDefault();
        setSubmitClicked(true);
        if (!customerDetails.order_name ||
            !orderCity ||
            !customerDetails.order_phone_number
        ) {
            return {}
        }
        formData.append("id", customerDetails.id);
        formData.append("order_name", customerDetails.order_name);
        formData.append("order_city", orderCity);
        formData.append("order_address", customerDetails.order_address);
        const match = orderDeliveryFee.match(/\d+/);
        const deliveryFee = match ? match[0] : null;
        formData.append("order_delivery_fee", deliveryFee);
        formData.append("order_phone_number", customerDetails.order_phone_number);
        formData.append("order_notes", customerDetails.order_notes);
        formData.append("order_sub_total", subTotal);

        try {
            await orderService.confirmOrder(formData);
            setOrderDone(true)
        } catch (error) {
            console.error("Error creating order:", error);
        }
    }

    if (orderData.generated_token) {
        const order = orderData;
        return (
            <>
                {orderDone ? (
                    <div dir="rtl" className="custom-order-page h-100 my-auto">
                        <div className="container h-100">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-md-10 col-xl-8">
                                    <div className="authentication-content bg-white px-1 px-lg-2 py-3 rounded-3 shadow-lg">
                                        <div className="row no-gutters">
                                            <div>
                                                <div className="auth-form p-3">
                                                    <div className="text-center mb-3"><FcPaid size="100"/></div>
                                                    <div className="text-center black-text-color fw-bold fs-5 mb-3">
                                                        شكرا لطلبكم!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="home-page custom-order-page bg-white">
                        <div className="icon-whatsapp-holder position-fixed end-0 bottom-0 mb-3 mb-xl-5 me-3 me-xl-5"
                             style={{zIndex: 9}}>
                            <div className="whtsapp-holder">
                                <Link className="social-icon px-1"
                                      to="https://api.whatsapp.com/send/?phone=212670998045"
                                      target="_blank">
                                    <div style={{backgroundColor: "#24d366"}} className="rounded-circle p-1 shadow">
                                        <BiLogoWhatsapp size="60" color="#fff"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-page-content px-0 pt-3 d-flex flex-column h-100">
                            <div className="home-page-header bg-white mb-3">
                                <div className="logo-holder mx-auto">
                                    <img className="logo img-fluid" src={mainLogo} alt="lcmodemaroc Logo"/>
                                </div>
                            </div>
                            <div
                                className="customer-order-page my-2 px-2 mx-auto col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7"
                                dir="rtl">
                                <div className="authentication-content bg-white px-1 px-lg-2 py-3 rounded-3 shadow-lg">
                                    <div>
                                        <div className="auth-form p-3">
                                            <div className="text-center black-text-color fw-bold fs-5 mb-3">
                                                معلومات الطلبية
                                            </div>
                                            <form onSubmit={onSubmit} encType="multipart/form-data">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_name">الاسم :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <BiUser size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               className={`form-control ${submitClicked && !customerDetails.order_name && 'border-danger'}`}
                                                               placeholder="الاسم الكامل" name="order_name"
                                                               id="order_name"
                                                               value={customerDetails.order_name} onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="row m-0">
                                                    <div className="col-lg-6 p-0 ps-lg-2">
                                                        <div className="mb-3">
                                                            <label className="mb-2 ps-3 gray-text-color small"
                                                                   htmlFor="order_city">المدينة :</label>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                    <LiaCitySolid size="20" color="#b7b7b7"/>
                                                                </div>
                                                                <Select
                                                                    className={`custom-searchable-select ${submitClicked && !orderCity && ' is-empty-input'}`}
                                                                    defaultValue={orderCity}
                                                                    isClearable={true}
                                                                    isRtl={true}
                                                                    isSearchable={true}
                                                                    name="order_city"
                                                                    id="order_city"
                                                                    options={cityData}
                                                                    onChange={(e) => onCityChange(e)}
                                                                    placeholder={orderCity}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 p-0 pe-lg-2">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2 ps-3 gray-text-color small"
                                                                   htmlFor="orderDeliveryFee">مبلغ التوصيل :</label>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                    <SlWallet size="20" color="#b7b7b7"/>
                                                                </div>
                                                                <input type="text" className="form-control" disabled
                                                                       placeholder="مبلغ التوصيل"
                                                                       name="orderDeliveryFee" id="orderDeliveryFee"
                                                                       value={orderDeliveryFee}
                                                                       onChange={onChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_address">العنوان :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <HiOutlineLocationMarker size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="عنوان التوصيل" name="order_address"
                                                               id="order_address"
                                                               value={customerDetails.order_address}
                                                               onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_phone_number">رقم الهاتف :</label>
                                                    <div className="position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                            <AiOutlinePhone size="20" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text"
                                                               className={`form-control ${submitClicked && !customerDetails.order_phone_number && 'border-danger'}`}
                                                               placeholder="رقم الهاتف" name="order_phone_number"
                                                               id="order_phone_number"
                                                               value={customerDetails.order_phone_number}
                                                               onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 position-relative">
                                                    <label className="mb-2 ps-3 gray-text-color small"
                                                           htmlFor="order_notes">معلومات اضافية :</label>
                                                    <div className="position-relative">
                                                            <textarea name="order_notes"
                                                                      id="order_notes" rows="3"
                                                                      className="form-control"
                                                                      value={customerDetails.order_notes}
                                                                      onChange={onChange}>
                                                            </textarea>
                                                    </div>
                                                </div>
                                                <div className="mb-3 gray-text-color">
                                                    المنتجات :
                                                </div>
                                                <OrderItems read_only={true} order_id={order.id} formData={formData}
                                                            setFormData={setFormData}/>
                                                {order.total > 0 && (
                                                    <div className="mb-4 d-flex align-items-center">
                                                        <div className="text-black-50 ps-2">
                                                            مجموع الطلبية:
                                                        </div>
                                                        <div className="fw-bold-500">
                                                            {order.total} درهم
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="submit-button-holder">
                                                    <button type="submit" className="btn submit-button px-5">
                                                        تأكيد الطلب
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-holder bg-black text-center text-white-50 pt-4 pb-2 mt-auto">
                                <div className="logo-holder mb-3">
                                    <img src={mainLogoIcon} alt="Lc Mode Maroc Logo" width="60"/>
                                </div>
                                <div className="mb-3 px-5 col-sm-11 col-md-10 col-lg-8 col-xl-6 mx-auto text-light">
                                    متجرنا هو وجهتك المثالية لتلبية احتياجاتك التسوقية..نهتم بتقديم ملابس عالية الجودة
                                    وبأسعار
                                    تنافسية..
                                    إن كنت من هواة الجديد في عالم الموضة ،فإننا نقدم لك تشكيلة متنوعة من الأزياء لتلبية
                                    مختلف
                                    الأذواق..
                                </div>
                                <div className="social-icons mb-3">
                                    <Link className="social-icon px-1" to="https://www.instagram.com/lc.mode.maroc"
                                          target="_blank">
                                        <BiLogoInstagram size="25"/>
                                    </Link>
                                    <Link className="social-icon px-1"
                                          to="https://www.facebook.com/people/LC-Mode-Maroc/100092386430015"
                                          target="_blank">
                                        <BiLogoFacebook size="25"/>
                                    </Link>
                                </div>
                                <Link className="social-icon px-1"
                                      to="/admin/orders">
                                    دخول خاص بالإدارة
                                </Link>
                                <div className="small mt-2">
                                    جميع الحقوق محفوظة &copy; 2024 <span className="fw-bold">LC Mode Maroc</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#000"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }
}

export default CustomerOrder;