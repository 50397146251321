import React, {useEffect, useState} from "react";
import {customerService} from "../../_services";
import {CustomerRow, Header} from "./Private";

const Customers = () => {
    const [customersData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await customerService.getCustomers();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching customers data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(customersData); // If search query is empty, show all orders
            return;
        }

        const filtered = customersData.filter((order) =>
            Object.values(order).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, customersData]);

    return (
        <>
            <Header title="الزبائن"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-customers-page">
                    <div className="fw-bold mb-2 mb-lg-3 px-3 fw-bold-500">قائمة الزبائن</div>
                    <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                        <div className="col-6 col-xl-4 col-xxl-3">
                            <div className="order-all-search-holder">
                                <label htmlFor="search" className="black-text-color mb-1 pe-2">البحث</label>
                                <input
                                    type="text"
                                    className="form-control py-2"
                                    value={searchQuery}
                                    id="search"
                                    onChange={handleSearchInputChange}
                                    placeholder="البحث"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">الاسم</td>
                                <td className="text-black-50">المدينة</td>
                                <td className="text-black-50">رقم الهاتف</td>
                                <td className="text-black-50">تاريخ التسجيل</td>
                                <td className="text-black-50">الحذف</td>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((customer) => (
                                    <CustomerRow
                                        key={customer.id}
                                        customer_id={customer.id}
                                        full_name={customer.full_name || ""}
                                        city={customer.city}
                                        phone_number={customer.phone_number}
                                        created_date={customer.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">لا يوجد زبون حتى الآن</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Customers;