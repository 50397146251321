import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import PrivateRouter from "./pages/private/PrivateRouter";
import AuthRouter from "./pages/auth/AuthRouter";
import AuthGuard from "./_helpers/AuthGuard";
import IsLogged from "./_helpers/IsLogged";
import {Home} from "./pages/public/Public";
import {Error} from "./pages/private/Private";
import CustomerOrder from "./pages/public/CustomerOrder";

function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            {/* Public Pages */}
            <Route index element={<Home/>}/>
            <Route index path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>

            {/* Private Pages */}
            <Route path="/admin/*" element={
              <AuthGuard>
                <PrivateRouter/>
              </AuthGuard>
            }/>

            {/* Authentication Pages */}
            <Route path="/auth/*" element={
              <IsLogged>
                <AuthRouter/>
              </IsLogged>
            }/>

            <Route path="/order/:token" element={<CustomerOrder />} />

            <Route path="*" element={<Error/>}/>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;