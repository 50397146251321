const Footer = () => {
    return (
        <div className="mt-auto pt-3 text-center">
            <div className="copyright-text gray-text-color small">
                جميع الحقوق محفوظة &copy; 2024 <span className="fw-bold">lcmodemaroc</span>
            </div>
        </div>
    );
}

export default Footer;