import {Routes, Route} from "react-router-dom";
import {
    PLayout,
    Dashboard,
    Orders,
    PendingOrders,
    UnconfirmedOrders,
    OrdersToConfirm,
    Order,
    Customers,
    Error
} from "./Private";

const PrivateRouter = () => {
    return (
        <Routes>
            <Route element={<PLayout/>}>
                <Route index element={<Orders/>}/>
                <Route index path="/" element={<Orders/>}/>
                <Route index path="/orders" element={<Orders/>}/>
                <Route path="/pending-orders" element={<PendingOrders/>}/>
                <Route path="/unconfirmed-orders" element={<UnconfirmedOrders/>}/>
                <Route path="/orders-to-confirm" element={<OrdersToConfirm/>}/>
                <Route path="/order/:oid" element={<Order/>}/>
                <Route index path="/customers" element={<Customers/>}/>
                {/*<Route index path="/dashboard" element={<Dashboard/>}/>*/}
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default PrivateRouter;