import axios from "axios";
import {accountService} from "./private/AccountService";

const Axios = axios.create({
    baseURL: 'https://vps-c88177c4.vps.ovh.net/'
    // baseURL: 'http://localhost:1234/'
});

//Interceptor for the token
Axios.interceptors.request.use(request => {
    if (accountService.isLogged()) {
        request.headers.Authorization = 'Bearer ' + accountService.getToken();
        request.headers =
            {
                'x-token': accountService.getToken()
            }
    }
    return request;
});

export default Axios;