import Axios from "../ServiceCaller";

let getCustomers = async () => {
    const { data } = await Axios.get("/customers");
    return data.Customers;
};
let deleteCustomer = async (customer_id) => {
    try {
        const response = await Axios.delete(`/customers`, {
            data: { id: customer_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Customer:", error);
        throw error;
    }
};
export const customerService = {
    getCustomers,
    deleteCustomer,
};