import Axios from "../ServiceCaller";

let getOrders = async (status) => {
    const { data } = await Axios.get(`/admin-orders/${status}`);
    return data.Orders;
};

let getPendingOrders = async () => {
    const { data } = await Axios.get("/admin-orders/pending");
    return data.Orders;
};

let getUnconfirmedOrders = async () => {
    const { data } = await Axios.get("/admin-orders/unconfirmed");
    return data.Orders;
};

let getOrdersToConfirm = async () => {
    const { data } = await Axios.get("/admin-orders/to-confirm");
    return data.Orders;
};

let getOrderDetails = async (order_id) => {
    const { data } = await Axios.get(`admin-orders/${order_id}/details`);
    return data.OrderDetails;
};

let getOrderItems = async (order_id) => {
    const { data } = await Axios.get(`admin-orders/${order_id}/order-items`);
    return data.OrderItems;
};

let updateOrderToConfirmed = async (orderData) => {
    const { data } = await Axios.put("admin-orders/make-confirmed", orderData);
    return data;
};

let updateOrderToWaited = async (orderData) => {
    const { data } = await Axios.put("admin-orders/make-waited", orderData);
    return data;
};

let deleteOrder = async (orderId) => {
    try {
        const response = await Axios.delete("/admin-orders/delete", {
            data: {id: orderId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting order:", error);
        throw error;
    }
}

let updateOrder = async (orderData) => {
    const { data } = await Axios.put("admin-orders/update", orderData);
    return data;
};

export const adminOrderService = {
    getOrders,
    getPendingOrders,
    getOrderDetails,
    getOrderItems,
    getUnconfirmedOrders,
    getOrdersToConfirm,
    updateOrderToConfirmed,
    updateOrderToWaited,
    deleteOrder,
    updateOrder,
};