import {NavLink} from "react-router-dom";
import {BiCartAlt, BiGroup, BiSolidDashboard} from "react-icons/bi";


const BottomNavbar = () => {
    const menuItem = [
        // {
        //     path: "/admin/dashboard",
        //     name: "Dashboard",
        //     icon: <BiSolidDashboard size="25"/>
        // },
        {
            path: "/admin/pending-orders",
            name: "طلبيات لإدخال الثمن",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/unconfirmed-orders",
            name: "طلبيات في انتظار التأكيد",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/orders-to-confirm",
            name: "طلبيات مؤكدة",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/orders",
            name: "توزيع الطلبيات المؤكدة",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/customers",
            name: "الزبائن",
            icon: <BiGroup size="30"/>
        },
    ]

    return (
        <div
            className="the-main-bottom-navbar d-lg-none position-fixed bottom-0 w-100 py-2 px-3 d-flex align-items-center holder-of-main-page">
            <div className="bottom-navbar-links w-100 d-flex justify-content-between">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            <NavLink to={item.path} key={index}
                                     className="nav-link-item d-flex flex-column align-items-center">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                <span className="nav-text text-center">{item.name}</span>
                            </NavLink>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default BottomNavbar;