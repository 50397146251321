import {Footer, Header, OrderRow, OrdersFilteredData} from "./Private";
import {adminOrderService} from "../../_services";
import {useEffect, useState} from "react";

const Orders = () => {
    const [status, setStatus] = useState(0);
    const all_status = [
        {
            id: 1,
            name: "متوفرة"
        },
        {
            id: 2,
            name: 'غير متوفرة'
        },
        {
            id: 3,
            name: 'ملغاة'
        }
    ]
    const [searchQuery, setSearchQuery] = useState("");

    const changeCategory = (status) => {
        setStatus(status);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Header title="الطلبيات"/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="order-all-status-holder">
                            <label htmlFor="all_status" className="black-text-color mb-1 pe-2">حالة الطلبية</label>
                            <select
                                className="form-control py-2"
                                onChange={(e) => changeCategory(e.target.value)}
                                id="all_status"
                            >
                                <option value="الكل">الكل</option>
                                {
                                    all_status.map((item) => (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="order-all-search-holder">
                            <label htmlFor="search" className="black-text-color mb-1 pe-2">البحث</label>
                            <input
                                type="text"
                                className="form-control py-2"
                                value={searchQuery}
                                id="search"
                                onChange={handleSearchInputChange}
                                placeholder="البحث"
                            />
                        </div>
                    </div>
                </div>

                <div className="bg-white p-3 rounded-3">
                    <OrdersFilteredData
                        status={status}
                        searchQuery={searchQuery}
                    />
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Orders;