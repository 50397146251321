import {BiEdit, BiLogoWhatsapp, BiTrash} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useState} from "react";
import {adminOrderService} from "../../../_services";
import Modal from 'react-bootstrap/Modal';

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const OrderRow = (props) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteOrder = async (orderId) => {
        setIsDeleting(true);

        try {
            await adminOrderService.deleteOrder(orderId);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting order video:", error);
        } finally {
            setIsDeleting(false);
            // Close the confirmation modal after deletion
            closeDeleteConfirmationModal();
        }
    };

    const openDeleteConfirmationModal = (orderId) => {
        setOrderToDelete(orderId);
        setShowConfirmationModal(true);
    };

    const closeDeleteConfirmationModal = () => {
        setOrderToDelete(null);
        setShowConfirmationModal(false);
    };

    if (props.order_id > 0) {
        return (
            <tr className="text-nowrap">
                <td>
                    <span className="fw-bold-500">#{props.order_id}</span>
                </td>
                <td>
                    {props.customer_name}
                </td>
                <td>
                    {props.city}
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        {props.phone_number && (
                            <div className="pb-1">
                                <Link className="social-icon px-1"
                                      to={`https://api.whatsapp.com/send/?phone=${props.phone_number.startsWith('0') ? '212'+props.phone_number.slice(1) : '212'+props.phone_number}`}
                                      target="_blank">
                                    <BiLogoWhatsapp size="22" color="#000"/>
                                </Link>
                            </div>
                        )}
                        <div>
                            {props.phone_number}
                        </div>
                    </div>
                </td>
                <td dir="rtl" className="">
                    {props.total} {props.total !== "*****" && 'درهم'}
                </td>
                <td>
                    {props.status}
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                <span className="text-center">
                    <Link to={`/admin/order/${props.order_id}`}>
                            <BiEdit size="22" className="edit-icon"/>
                    </Link>
                    <button className="border-0 bg-transparent">
                        {isDeleting ? (
                            <span className="text-danger">حذف...</span>
                        ) : (
                            <BiTrash onClick={() => openDeleteConfirmationModal(props.order_id)} size="22"
                                     className="edit-icon text-danger"/>
                        )}
                    </button>
                </span>
                </td>
                {/* Confirmation Modal */}
                <Modal show={showConfirmationModal} onHide={closeDeleteConfirmationModal} centered dir="rtl">
                    <Modal.Header>
                        <Modal.Title>تحذير</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>هل أنت متأكد أنك تريد حذف هذا الطلب؟</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={closeDeleteConfirmationModal}>إلغاء</button>
                        <button className="btn btn-danger" onClick={() => handleDeleteOrder(orderToDelete)}>حذف</button>
                    </Modal.Footer>
                </Modal>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="5" className="text-center">There is no order yet</td>
            </tr>
        );
    }
}

export default OrderRow;