import {Header} from "./Private";
import {BiCopyAlt, BiLogoWhatsapp, BiUser} from "react-icons/bi";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {adminOrderService} from "../../_services";
import DeliveryCities from "../../_services/data/livraison_cities_last.json";
import {LiaCitySolid} from "react-icons/lia";
import Select from "react-select";
import {SlWallet} from "react-icons/sl";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {AiOutlinePhone} from "react-icons/ai";
import OrderItems from "../../components/private/order/OrderItems";

const Order = () => {
    let {oid} = useParams();
    let navigate = useNavigate();
    const [orderData, setData] = useState([]);
    const [customerDetails, setCustomerDetails] = useState({
        order_name: '',
        order_address: '',
        order_phone_number: '',
        order_notes: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminOrderService.getOrderDetails(oid);
                setData(data);
                if (data.length > 0) {
                    const {
                        customer_name,
                        city,
                        order_address,
                        phone_number,
                        order_notes,
                        delivery_fee,
                        sub_total,
                        total
                    } = data[0];
                    setCustomerDetails({
                        order_name: customer_name,
                        order_address: order_address,
                        order_phone_number: phone_number,
                        order_notes: order_notes,
                    });
                    const order_delivery_fee = `${delivery_fee} درهم`
                    setOrderCity(city);
                    setOrderDeliveryFee(order_delivery_fee);
                    setSubTotal(sub_total)
                    setShowTotal(total > 0)
                    setFinalTotal(total)
                    setOrderDeliveryPrice(delivery_fee)
                }
            } catch (error) {
                console.error("Error fetching order data:", error);
            }
        };

        fetchData();
    }, [oid]);
    const [orderCity, setOrderCity] = useState(0);
    const [orderDeliveryFee, setOrderDeliveryFee] = useState(0);
    const [cityData, setCityData] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [showTotal, setShowTotal] = useState(false);
    const [formData, setFormData] = useState(new FormData());
    const [submitType, setSubmitType] = useState("");
    const [doneWithCustomUrl, setDoneWithCustomUrl] = useState(false);
    const [generatedToken, setGeneratedToken] = useState("");
    const [finalTotal, setFinalTotal] = useState(0);
    const [orderDeliveryPrice, setOrderDeliveryPrice] = useState(0);

    useEffect(() => {
        calculPriceTotal();
    }, [orderDeliveryFee]);

    const calculPriceTotal = () => {

        let total = 0;
        // for (let i = 0; i < priceInputs.length; i++) {
        //     const price = parseFloat(priceInputs[i]);
        //     if (!isNaN(price)) {
        //         total += price;
        //     }
        // }

        const deliveryFee = parseFloat(orderDeliveryFee);
        if (!isNaN(deliveryFee)) {
            total += deliveryFee;
        }

        if (deliveryFee === 0 || (deliveryFee === total)) {
            setShowTotal(false);
        } else {
            setShowTotal(true);
        }

        // Update the subTotal state
        setSubTotal(total);
    };

    const onCityChange = (event) => {
        if (event) {
            const delivery_fee = parseFloat(event.livraison_fees.replace(/\D/g, ''));
            const order_city_value = event.value || '';
            setOrderCity(order_city_value);
            setOrderDeliveryFee((delivery_fee + 5) + " درهم");
        } else {
            const delivery_fee = 0;
            setOrderDeliveryFee(delivery_fee);
        }
        calculPriceTotal();
    };

    useEffect(() => {
        setCityData(DeliveryCities)
    }, []);

    useEffect(() => {
    }, [submitType]);

    const onChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value,
        });
    }

    const onSubmit = async (e, submit_type) => {
        e.preventDefault();
        setSubmitClicked(true);
        if (!customerDetails.order_name ||
            !orderCity ||
            !customerDetails.order_phone_number
        ) {
            return {}
        }
        formData.append("id", oid);
        formData.append("order_name", customerDetails.order_name);
        formData.append("order_city", orderCity);
        formData.append("order_address", customerDetails.order_address);
        const match = orderDeliveryFee.match(/\d+/);
        const deliveryFee = match ? match[0] : null;
        formData.append("order_delivery_fee", deliveryFee);
        formData.append("order_phone_number", customerDetails.order_phone_number);
        formData.append("order_notes", customerDetails.order_notes);
        formData.append("order_sub_total", subTotal);

        try {
            await adminOrderService.updateOrder(formData)
                .then(res => {
                    if (res.generated_token) {
                        setGeneratedToken(res.generated_token)
                    }
                })
                .catch(error => console.log(error))
            if (submit_type === 'send_to_confirmation') {
                setDoneWithCustomUrl(true)
            } else {
                setDoneWithCustomUrl(false)
                navigate('/admin/orders', {replace: true})
            }
        } catch (error) {
            console.error("Error creating order:", error);
        }
    }

    const onSubmitOrder = (e, submit_type) => {
        e.preventDefault();
        if (submit_type) {
            setSubmitType(submit_type);
            formData.append("submit_type", submit_type);
            onSubmit(e, submit_type);
        }
    }

    const handleCopy = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // alert('Link copied to clipboard!');
            })
            .catch((error) => {
                console.error('Error copying link to clipboard: ', error);
            });
    };

    return (
        <>
            <Header title="الطلبيات"/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                {doneWithCustomUrl ? (
                    <div className="p-3 bg-white rounded-3 text-center" dir="rtl">
                        <div className="fw-bold text-center mb-3">
                            تم إنشاء الرابط المخصص لهذا الطلب بنجاح
                        </div>
                        <div className="mb-2">الرابط :</div>
                        <div className="mb-2" dir="ltr">
                            <Link to={`https://order.lcmodemaroc.com/order/${generatedToken}`}>
                                {`https://order.lcmodemaroc.com/order/${generatedToken}`}
                            </Link>
                        </div>
                        <div className="copy-btn-holder mb-3">
                            <button onClick={() => handleCopy(`https://order.lcmodemaroc.com/order/${generatedToken}`)}
                                    type="button"
                                    className="profile-link border-0 bg-transparent">
                                <div className="d-flex align-items-center copy-btn py-1 rounded-3">
                                    <div className="fw-bold-500 me-1">
                                        نسخ الرابط
                                    </div>
                                    <div className="me-1">
                                        <BiCopyAlt size="20"/>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mb-3 text-center">
                            <Link className="social-icon px-1 btn btn-success"
                                  to={`https://api.whatsapp.com/send/?phone=${(customerDetails.order_phone_number).startsWith('0') ? '212' + (customerDetails.order_phone_number).slice(1) : '212' + customerDetails.order_phone_number}`}
                                  target="_blank">
                                فتح المحادثة مع الزبون
                                <BiLogoWhatsapp size="25"/>
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className="admin-order-page" dir="rtl">
                        <div className="authentication-content bg-white px-1 px-lg-2 py-3 rounded-3 shadow-lg">
                            <div>
                                <div className="auth-form p-3">
                                    <div className="text-center black-text-color fw-bold fs-5 mb-3">
                                        معلومات الطلبية
                                    </div>
                                    {orderData.length > 0 && (
                                        <form encType="multipart/form-data">
                                            <div className="form-group mb-3">
                                                <label className="mb-2 ps-3 gray-text-color small"
                                                       htmlFor="order_name">الاسم :</label>
                                                <div className="position-relative">
                                                    <div
                                                        className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                        <BiUser size="20" color="#b7b7b7"/>
                                                    </div>
                                                    <input type="text"
                                                           className={`form-control ${submitClicked && !customerDetails.order_name && 'border-danger'}`}
                                                           placeholder="الاسم الكامل" name="order_name"
                                                           id="order_name"
                                                           value={customerDetails.order_name} onChange={onChange}/>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-lg-6 p-0 ps-lg-2">
                                                    <div className="mb-3">
                                                        <label className="mb-2 ps-3 gray-text-color small"
                                                               htmlFor="order_city">المدينة :</label>
                                                        <div className="position-relative">
                                                            <div
                                                                className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                <LiaCitySolid size="20" color="#b7b7b7"/>
                                                            </div>
                                                            <Select
                                                                className={`custom-searchable-select ${submitClicked && !orderCity && ' is-empty-input'}`}
                                                                defaultValue={orderCity}
                                                                isClearable={true}
                                                                isRtl={true}
                                                                isSearchable={true}
                                                                name="order_city"
                                                                id="order_city"
                                                                options={cityData}
                                                                onChange={(e) => onCityChange(e)}
                                                                placeholder={orderCity}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 p-0 pe-lg-2">
                                                    <div className="form-group mb-3">
                                                        <label className="mb-2 ps-3 gray-text-color small"
                                                               htmlFor="orderDeliveryFee">مبلغ التوصيل :</label>
                                                        <div className="position-relative">
                                                            <div
                                                                className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                                <SlWallet size="20" color="#b7b7b7"/>
                                                            </div>
                                                            <input type="text" className="form-control" disabled
                                                                   placeholder="مبلغ التوصيل"
                                                                   name="orderDeliveryFee" id="orderDeliveryFee"
                                                                   value={orderDeliveryFee}
                                                                   onChange={onChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="mb-2 ps-3 gray-text-color small"
                                                       htmlFor="order_address">العنوان :</label>
                                                <div className="position-relative">
                                                    <div
                                                        className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                        <HiOutlineLocationMarker size="20" color="#b7b7b7"/>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="عنوان التوصيل" name="order_address"
                                                           id="order_address"
                                                           value={customerDetails.order_address}
                                                           onChange={onChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="mb-2 ps-3 gray-text-color small"
                                                       htmlFor="order_phone_number">رقم الهاتف :</label>
                                                <div className="position-relative">
                                                    <div
                                                        className="icon-holder position-absolute top-50 translate-middle-y me-2">
                                                        <AiOutlinePhone size="20" color="#b7b7b7"/>
                                                    </div>
                                                    <input type="text"
                                                           className={`form-control ${submitClicked && !customerDetails.order_phone_number && 'border-danger'}`}
                                                           placeholder="رقم الهاتف" name="order_phone_number"
                                                           id="order_phone_number"
                                                           value={customerDetails.order_phone_number}
                                                           onChange={onChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4 position-relative">
                                                <label className="mb-2 ps-3 gray-text-color small"
                                                       htmlFor="order_notes">معلومات اضافية :</label>
                                                <div className="position-relative">
                                                            <textarea name="order_notes"
                                                                      id="order_notes" rows="3"
                                                                      className="form-control"
                                                                      value={customerDetails.order_notes}
                                                                      onChange={onChange}>
                                                            </textarea>
                                                </div>
                                            </div>
                                            <div className="mb-3 gray-text-color">
                                                المنتجات :
                                            </div>
                                            <OrderItems
                                                orderDeliveryPrice={orderDeliveryPrice}
                                                finalTotal={finalTotal}
                                                setFinalTotal={setFinalTotal}
                                                order_id={orderData[0].id}
                                                formData={formData}
                                                setFormData={setFormData}
                                            />
                                            {orderData[0].sub_total > 0 && (
                                                <div className="mb-4 d-flex align-items-center">
                                                    <div className="text-black-50 ps-2">
                                                        مجموع الطلبية:
                                                    </div>
                                                    <div className="fw-bold-500">
                                                        {finalTotal} درهم
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'في انتظار الثمن' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'send_to_confirmation')}>
                                                            إرسال للتأكيد
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'cancel_order')}>
                                                            إلغاء الطلب
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'في انتظار التأكيد' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'order_confirmation')}>
                                                            تأكيد الطلب
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'cancel_order')}>
                                                            إلغاء الطلب
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'مؤكدة' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'available')}>
                                                            متوفرة
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'unavailable')}>
                                                            غير متوفرة
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'cancel_order')}>
                                                            إلغاء الطلب
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'متوفرة' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'unavailable')}>
                                                            غير متوفرة
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'cancel_order')}>
                                                            إلغاء الطلب
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'غير متوفرة' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'available')}>
                                                            متوفرة
                                                        </button>
                                                    </div>
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'cancel_order')}>
                                                            إلغاء الطلب
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {orderData[0].status === 'ملغاة' && (
                                                <div
                                                    className="d-flex justify-content-center justify-content-between mt-3 flex-wrap">
                                                    <div className="mb-2">
                                                        <button type="submit" className="btn submit-button px-5"
                                                                onClick={(e) => onSubmitOrder(e, 'available')}>
                                                            متوفرة
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/*End Page Content*/}
            </div>
        </>
    );
}


export default Order;